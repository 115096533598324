






































import { Component, Mixins, Ref } from 'vue-property-decorator'

import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import SocialResponseMixin from '@/mixins/SocialResponseMixin'
import AuthModule from '@/store/modules/auth'
import MainTourModal from '@/components/modals/MainTourModal.vue'
import MiniTourModal from '@/components/modals/MiniTourModal.vue'
import MasterEducationModule from '@/store/modules/master/education'
import NotifyMixin from '@/mixins/NotifyMixin'
import { clearStorage } from '@/utils/functions'
import { SocialType } from '@/store/types'

@Component({
  components: {
    ButtonTextIcon,
    Confirmation,
    LinkTabs,
    MainTourModal,
    MiniTourModal,
  },
})
export default class Profile extends Mixins(SocialResponseMixin, NotifyMixin) {
  @Ref() confirm!: Confirmation

  private get vertical() {
    return this.$vuetify.breakpoint.width > 1024
  }

  private get tabs () {
    return [
      {
        icon: '$profile',
        name: 'Данные',
        to: { name: 'profile' },
      },
      {
        icon: '$payment',
        name: 'Оплаты',
        to: { name: 'profile.payments' },
      },
      {
        icon: '$courses',
        name: 'Курсы',
        to: { name: 'profile.courses' },
      },
      {
        icon: '$settings',
        name: 'Уведомления',
        to: { name: 'profile.notifications' },
      },
    ]
  }

  private get masterGroups() {
    return MasterEducationModule.masterGroups
  }

  private get hasSocials() {
    return AuthModule.self?.hasSocials
  }

  private get isCurrentCourse() {
    return MasterEducationModule.currentMasterGroupCourseID
  }

  private get visibleTour() {
    return Boolean(this.hasSocials && this.isCurrentCourse)
  }

  private get isVKRedirect () {
    return AuthModule.isVKRedirect
  }

  private get socialMediaIcon() {
    return require(`@/assets/images/social/${this.socialMediaChat}.png`)
  }
  private socialMediaChat = SocialType.TELEGRAM
  private botLink = `https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_NAME}`

  private visibleMainTourModal = false
  private visibleMiniTourModal = false

  private mounted () {
    this.checkSocialResponse()
    if (!this.masterGroups.length && this.hasSocials)
      MasterEducationModule.fetchMasterGroups()
        .catch((err) => {
          // TODO: Рассмотреть возможность сделать иначе в рамках упрощения регистрации
          // После этапа подсвечивания обязательных полей при входе в профиль
          if (!this.isVKRedirect) this.notifyError(err)
        })
  }

  private confirmLogout () {
    this.confirm.open(
      'Выход из профиля',
      'Вы уверены, что хотите выйти из учетной записи?',
      {
        buttonConfirmText: 'Выйти',
      },
    )
      .then(this.handleLogout)
      .catch(() => {return})
  }

  private handleLogout () {
    AuthModule.logout()
      .then(() => {
        clearStorage()
        window.location.href = '/auth/login'
      })
      .catch(this.notifyError)
  }

  private mainTourStart() {
    if (this.isCurrentCourse && this.masterGroups.length && this.isCurrentCourse !== this.masterGroups[0].id) {
      MasterEducationModule.setCurrentMasterGroupID(this.isCurrentCourse)
    }
    this.$router.push({ name: 'master' })
      .then(() => {
        this.$bus.$emit('startTour')
      })
  }

  private miniTourStart() {
    this.$router.push({ name: 'master.lessons' })
      .then(() => {
        this.$bus.$emit('startLessonExerciseTour', 'lesson')
      })
  }
}
